import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import { BlockHeadline, HeadSection, Layout } from '../components/layout';
import Faqs from '../components/Faqs';
import Seo from '../components/Seo';

function FaqPage({
  data: {
    allStrapiCategory: { nodes: faqCategories },
    // metaData: { sitetitle, description },
  },
}) {
  const intl = useIntl();

  return (
    <Layout>
      <HeadSection headline>
        <BlockHeadline align="center" sizeRestriction="none" content={intl.formatMessage({ id: 'faq' })} variant="h1" />
      </HeadSection>
      <Faqs bgColorInverted data={faqCategories} />
    </Layout>
  );
}

export const query = graphql`
  {
    allStrapiCategory(sort: { fields: priority, order: ASC }) {
      nodes {
        id
        name
        faqs {
          id
          Slug
          Title
          priority
        }
      }
    }
    metaData {
      description
      sitetitle
    }
  }
`;

export default FaqPage;

// eslint-disable-next-line react/prop-types
export function Head({ data }) {
  // eslint-disable-next-line react/prop-types
  return <Seo title={data.metaData.sitetitle} description={data.metaData.description} />;
}
