import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Box, Hidden, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { BlockHeadline } from './layout';
import { AnchorLink, Container, Link } from './theme';

function sortByPriority(a, b) {
  return a.priority - b.priority;
}

function Faqs({ bgColorInverted, data: faqCategories }) {
  const intl = useIntl();

  return (
    <Box
      sx={[
        (theme) => ({
          padding: '40px 0 25px',
          [theme.breakpoints.up('sm')]: {
            padding: '60px 0 10px',
          },
          [theme.breakpoints.up('md')]: {
            padding: '90px 0 30px',
          },
          '& h2': {
            marginBottom: '24px',
            [theme.breakpoints.up('sm')]: {
              marginBottom: '32px',
            },
            [theme.breakpoints.up('md')]: {
              marginBottom: '35px',
            },
          },
        }),
        bgColorInverted &&
          ((theme) => ({ backgroundColor: theme.palette.background.paper, color: theme.palette.text.secondary })),
      ]}
    >
      <Container>
        <Grid container>
          <Hidden mdDown>
            <Grid item md={4}>
              <Box
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.25)',
                  maxWidth: '250px',
                  padding: '20px 24px',
                  position: 'sticky',
                  top: '150px',
                  width: '85%',
                }}
              >
                <Box component="ul" sx={{ listStyle: 'none', margin: '0', padding: '0' }}>
                  {faqCategories.map((category) => (
                    <Box
                      component="li"
                      key={category.name}
                      sx={{
                        marginBottom: '20px',
                        '&:last-child': {
                          margin: '0',
                        },
                      }}
                    >
                      <AnchorLink
                        offset="130"
                        href={`#${category.name.replace(/[^A-Z0-9]/gi, '_')}`}
                        sx={(theme) => ({
                          color: 'inherit',
                          display: 'block',
                          fontSize: theme.typography.pxToRem(18),
                          fontWeight: '500',
                          textDecoration: 'none',
                          '&:focus, &:hover': {
                            color: theme.palette.link.primary,
                          },
                        })}
                      >
                        {category.name} <ChevronRightIcon sx={{ position: 'absolute', right: '5px' }} />
                      </AnchorLink>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            {faqCategories.map((category) => {
              return (
                <Box key={category.id}>
                  <Grid container justifyContent="space-between" sx={{ flexWrap: 'nowrap' }}>
                    <Grid item id={category.name.replace(/[^A-Z0-9]/gi, '_')}>
                      <BlockHeadline sizeRestriction="none" content={category.name} variant="h2" />
                    </Grid>
                    <Hidden mdDown>
                      <Grid item>
                        <Link
                          to="/faq"
                          sx={(theme) => ({
                            color: theme.palette.secondary.main,
                            display: 'inline-block',
                            fontSize: theme.typography.pxToRem(18),
                            fontWeight: '500',
                            marginTop: '20px',
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                            [theme.breakpoints.down('lg')]: {
                              marginTop: '12px',
                            },
                          })}
                        >
                          <Typography noWrap>{intl.formatMessage({ id: 'backToTop' })}</Typography>
                        </Link>
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Box component="ul" sx={{ listStyle: 'none', margin: '0 0 40px', paddingLeft: '25px' }}>
                    {category.faqs.sort(sortByPriority).map((faq) => {
                      return (
                        <Box
                          component="li"
                          key={faq.id}
                          sx={(theme) => ({
                            fontSize: theme.typography.pxToRem(18),
                            fontWeight: '500',
                            lineHeight: '1.5em',
                            marginBottom: '16px',
                            position: 'relative',
                            '&:before': {
                              backgroundColor: theme.palette.secondary.main,
                              borderRadius: '50%',
                              content: '""',
                              display: 'block',
                              height: '9px',
                              left: '-25px',
                              position: 'absolute',
                              top: '8px',
                              width: '9px',
                            },
                          })}
                        >
                          <Link
                            to={`/faq/${faq.Slug}`}
                            sx={{
                              textDecoration: 'none',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            {faq.Title}
                          </Link>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

Faqs.propTypes = {
  bgColorInverted: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

Faqs.defaultProps = {
  bgColorInverted: false,
};

export default Faqs;
